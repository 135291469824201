#contact
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    background: #F9F9FF
    padding: 8vh 0

#contact-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center

#contact-text-container
    margin-bottom: 2vh

    h1
        text-align: center
        color: rgb(255, 255, 255)

#contact-toggle-button
    margin-bottom: 4vh

#contact-form
    width: 50%
    background-color: #fff
    border: 1px solid #d9d9d9
    border-radius: 15px
    height: 0px
    display: none
    overflow: hidden
    transition: height 1s

#contact-form-btns
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-around

#contact-done,
#contact-wait
    width: 50%
    padding: 1rem
    text-align: center
    background-color: white
    border: 1px solid #d9d9d9
    border-radius: 15px
    color: rgb(255, 255, 255)
    background: rgb(255, 255, 255, 0)

#contact-done-button
    margin-bottom: 2vh

@media only screen and (max-width: 1921px)
    #contact-container
        width: 80%

@media only screen and (max-width: 1441px)
    #contact-container
        width: 80%

    #contact-form,
    #contact-done,
    #contact-wait
        width: 60%

@media only screen and (max-width: 1025px)
    #contact-container
        width: 90%

    #contact-form,
    #contact-done,
    #contact-wait
        width: 70%

@media only screen and (max-width: 901px)
    #contact-container
        width: 90%

@media only screen and (max-width: 769px)
    #contact
        width: 100%
        height: auto
        padding: 6vh 0

    #contact-container
        width: 80%
        height: auto
        flex-direction: column

    #contact-form,
    #contact-done,
    #contact-wait
        width: 80%

@media only screen and (max-width: 550px)

@media only screen and (max-width: 426px)
    #contact-text-container
        h4
            font-size: 15px
            font-weight: normal

    #contact-form
        width: 100%

    #contact-done,
    #contact-wait
        width: 90%
