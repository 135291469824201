#services
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: #F9F9FF
    padding: 8vh 0

#services-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

#services-title-container
    margin-bottom: 8vh

    h1
        text-align: center

#services-items-container
    width: 100%
    height: 60%
    display: flex
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-evenly
    align-items: center

    .services-items
        width: 25%
        margin: 20px
        padding: 2px
        border-radius: 15px

        .services-items-wrappers
            padding: 20px
            border-radius: 13px
            background: rgb(255, 255, 255)

        .services-items-top
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center
            margin-bottom: 20px

            .services-items-title
                font-size: 1.25em
                font-weight: bold
                width: 60%

            svg
                width: 30%
                height: 30%

    .services-items:hover
        cursor: default

@media only screen and (max-width: 1921px)
    #services-container
        width: 80%

@media only screen and (max-width: 1441px)
    #services-container
        width: 80%

    #services-title-container
        margin-bottom: 5vh

    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 14px

@media only screen and (max-width: 1025px)
    #services-container
        width: 90%

        .services-items
            width: 40%

    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 16px
                svg
                    width: 25%
                    height: 25%

@media only screen and (max-width: 901px)
    #services-container
        width: 90%

        .services-items
            width: 40%

@media only screen and (max-width: 769px)
    #services
        width: 100%
        height: auto
        padding: 6vh 0

    #services-title-container
        margin-bottom: 3vh

    #services-container
        width: 80%
        height: auto
        flex-direction: column

    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 14px

@media only screen and (max-width: 651px)
    #services-container
        width: 80%

        .services-items
            width: 80%

    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 1.2rem
                svg
                    width: 20%
                    height: 20%

@media only screen and (max-width: 551px)
    #services-container
        .services-items
            width: 80%

    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 1.2rem
            .services-items-body
                font-size: 14px

@media only screen and (max-width: 426px)
    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 1rem
            .services-items-body
                font-size: 13px

@media only screen and (max-width: 376px)
    #services-items-container
        .services-items
            .services-items-top
                .services-items-title
                    font-size: 13px
            .services-items-body
                font-size: 12px
