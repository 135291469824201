#common-application-container
    width: 100%
    height: 100vh
    display: flex
    flex-direction: row

#common-application-nav-container
    display: none
    position: fixed
    padding: 6px 8px
    left: 10px
    top: 10px
    border: 2px solid black
    border-radius: 15px

    div:nth-of-type(odd)
        font-size: 12px

    div:nth-of-type(even)
        font-size: 24px

#common-application-menu-container
    display: flex
    position: relative
    flex-direction: column
    width: 20%
    padding: 20px
    overflow: auto

    h1
        margin: 0

#common-application-slider
    display: block
    position: relative
    height: 100vh
    width: 10px
    background-color: rgba(0, 0, 0, 0.5)

#common-application-slider:hover
    cursor: col-resize

#common-application-menu-title
    margin: 20px 0
    display: flex
    flex-direction: row
    justify-content: space-between

    h2
        margin: 0

#common-application-items-container
    display: flex

#common-application-animation-container
    display: block
    position: relative
    height: 100vh
    background-position: center
    background-size: cover

#common-application-top-button
    position: relative
    text-align: right

.common-application-references-list
    margin-bottom: 10px

.common-button
    display: block
    margin: auto

.common-dropdown-menu
    margin-bottom: 16px

    .common-dropdown-menu-selector,
    .common-dropdown-menu-selectee
        display: inline-flex !important
        align-items: center !important
        justify-content: flex-start !important

    .common-dropdown-menu-selectee
        justify-content: center !important

.common-image-icon-container
    width: 100%
    height: 100%
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    z-index: 1

    .common-image-icon
        color: #1677FF
        font-size: 2rem

.common-image-icon-container:hover
    cursor: pointer

.common-image-picker
    position: relative
    display: block
    margin: 5px auto
    width: 90%
    border-radius: 10px

.common-url-input-container
    display: flex
    flex-direction: column

.common-url-image-container
    padding: 16px 0
    display: flex
    flex-direction: column
    align-items: center

    img
        display: none
        width: 100%

.common-color-picker
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: 16px

    .chrome-picker,
    .hue-picker
        margin-bottom: 16px

    .chrome-picker,
    button
        width: 100% !important

.common-uploader-container
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between

.common-uploader-file-name
    width: 100%
    text-align: center
    font-size: small

.common-uploader-image-container
    padding: 16px 0

    img
        width: 100%

.ant-drawer-title
    color: rgb(0, 0, 0) !important

.common-function-graph-chart
    width: auto

.common-function-graph-chart-title
    position: relative
    margin: auto
    width: max-content

@media only screen and (max-width: 1921px)

@media only screen and (max-width: 1441px)

@media only screen and (max-width: 1025px)
    #common-application-menu-container
        p
            font-size: 14px

@media only screen and (max-width: 901px)

@media only screen and (max-width: 769px)
    #common-application-menu-container
        p
            font-size: 13px

@media only screen and (max-width: 551px)
    #common-application-nav-container
        display: block

    #common-application-menu-container
        display: none

    #common-application-slider
        display: none

    #common-application-animation-container
        width: 100%

@media only screen and (max-width: 426px)

@media only screen and (max-width: 376px)
