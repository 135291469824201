#projects
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: rgb(255, 255, 255)
    padding: 8vh 0

#projects-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

#projects-title-container
    margin-bottom: 8vh

    h1
        text-align: center
    h4
        color: #777777

#projects-items-container
    width: 100%
    height: 60%
    display: flex
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-evenly
    align-items: center

    .projects-items
        width: 25%
        margin: 20px
        padding: 2px
        border-radius: 15px
        display: flex
        flex-direction: column
        justify-content: space-between

        .projects-items-wrappers
            width: 97%
            padding: 5px
            border-radius: 13px
            background: rgb(255, 255, 255)
            position: relative

        .projects-items-top
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center

            .projects-items-image
                width: 100%
                border-radius: 13px

            .projects-items-background
                display: none
                justify-content: center
                align-items: center
                position: absolute
                width: 97%
                height: 97%
                border-radius: 13px
                opacity: 0.5

            .projects-items-zoom
                display: none
                position: absolute

                svg
                    width: 50px
                    height: 50px
                    opacity: 0.8
                    z-index: 20

        .projects-items-title
            font-size: 1.25rem
            font-weight: bold
            text-align: center
            margin: 20px 0

        .projects-items-type
            font-size: 1rem
            text-align: center
            color: #777777
            margin-bottom: 10px

    .projects-items:hover
        cursor: default

        .projects-items-top
            cursor: pointer

            .projects-items-background
                display: flex

            .projects-items-zoom
                display: block

@media only screen and (max-width: 1921px)
    #projects-container
        width: 80%

@media only screen and (max-width: 1441px)
    #projects-container
        width: 80%

    #projects-title-container
        margin-bottom: 5vh

    #projects-items-container
        .projects-items
            .projects-items-title
                font-size: 14px

@media only screen and (max-width: 1025px)
    #projects-container
        width: 90%

        .projects-items
            width: 40%

    #projects-items-container
        .projects-items
            .projects-items-title
                font-size: 16px
                // svg
                //     width: 25%
                //     height: 25%

@media only screen and (max-width: 901px)
    #projects-container
        width: 90%

        .projects-items
            width: 40%

@media only screen and (max-width: 769px)
    #projects
        width: 100%
        height: auto
        padding: 6vh 0

    #projects-title-container
        margin-bottom: 3vh

    #projects-container
        width: 80%
        height: auto
        flex-direction: column

    #projects-items-container
        .projects-items
            height: 42vh

            .projects-items-wrappers
                .projects-items-title
                    font-size: 14px

@media only screen and (max-width: 651px)
    #projects-container
        width: 80%

    #projects-title-container
        h4
            text-align: center
            font-size: 14px

    #projects-items-container
        .projects-items
            width: 60%
            height: 45vh
            .projects-items-wrappers
                .projects-items-title
                    font-size: 1.2rem
                svg
                    width: 20%
                    height: 20%

@media only screen and (max-width: 581px)
    #projects-title-container
        h4
            font-size: 1rem

    #projects-items-container
        .projects-items
            width: 60%
            height: 45vh

@media only screen and (max-width: 551px)
    #projects-container
        .projects-items
            width: 60%

    #projects-title-container
        width: 80%

    #projects-items-container
        .projects-items
            width: 80%
            height: 50vh
            .projects-items-title
                font-size: 1.2rem
            .projects-items-type
                font-size: 1rem

@media only screen and (max-width: 501px)
    #projects-items-container
        .projects-items
            height: 47vh

@media only screen and (max-width: 426px)
    #projects-title-container
        width: 100%

    #projects-items-container
        .projects-items
            width: 70%
            height: 36vh
            .projects-items-wrappers
                padding: 4px
            .projects-items-title
                margin: 10px 0
                font-size: 1rem
            .projects-items-type
                font-size: 14px

@media only screen and (max-width: 376px)
    #projects-items-container
        .projects-items
            width: 80%
