#aboutme
    width: 100%
    height: 80vh
    display: flex
    justify-content: center
    align-items: center
    background: rgb(255, 255, 255, 0.8)

#aboutme-container
    width: 50%
    height: 60vh
    margin: auto
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

#aboutme-text-container
    h1
        text-align: center

#aboutme-skills-container
    width: 100%
    height: 60%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

.aboutme-skills
    width: 30%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.skill-card
    background-color: transparent
    width: 100%
    height: 100%
    border: 2px solid white
    perspective: 1000px

.skill-card-inner
    position: relative
    width: 100%
    height: 100%
    text-align: center
    transition: transform 0.8s
    transform-style: preserve-3d

/* Do an horizontal flip when you move the mouse over the flip box container */
.skill-card:hover .skill-card-inner
    transform: rotateY(180deg)

/* Position the front and back side */
.skill-card-front,
.skill-card-back
    position: absolute
    height: 100%
    -webkit-backface-visibility: hidden
    backface-visibility: hidden
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 15px

/* Style the front side (fallback if image is missing) */
.skill-card-front
    color: black
    background-color: rgba(0, 0, 0, 0.1)
    border: 2px solid grey

    img
        width: 50%

/* Style the back side */
.skill-card-back
    color: white
    background-color: rgba(0, 0, 0, 0.1)
    transform: rotateY(180deg)
    padding: 0 20px
    cursor: default

@media only screen and (max-width: 1921px)
    #aboutme-container
        width: 80%

@media only screen and (max-width: 1441px)
    #aboutme-container
        width: 80%

@media only screen and (max-width: 1025px)
    #aboutme-container
        width: 90%

@media only screen and (max-width: 901px)
    #aboutme-container
        width: 90%

@media only screen and (max-width: 769px)
    #aboutme
        width: 100%
        height: auto
        padding: 6vh 0

    #aboutme-container
        width: 80%
        height: auto
        flex-direction: column

    #aboutme-skills-container
        width: 100%
        height: 80vh
        display: flex
        flex-direction: column
        justify-content: space-evenly

    .aboutme-skills
        width: 60%
        height: 30%

    .skill-card-front
        img
            width: 30%
        h3
            margin: 10px 0

@media only screen and (max-width: 550px)
    .aboutme-skills
        width: 80%
        height: 30%

    .skill-card-front
        img
            width: 25%
        h3
            margin: 10px 0

@media only screen and (max-width: 426px)
    #aboutme-text-container
        h4
            font-size: 15px
            font-weight: normal

    .aboutme-skills
        width: 100%
        height: 30%

@media only screen and (max-width: 376px)
    .skill-card-back
        font-size: 13px
