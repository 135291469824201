#construction
    width: 100%
    height: 30vh
    display: flex
    justify-content: center
    align-items: center
    background: rgb(255, 255, 255, 0.8)

#construction-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    h4
        text-align: center

#construction-text-container
    h1
        text-align: center
    h3
        padding: 0 20px

@media only screen and (max-width: 1921px)
    #construction-container
        width: 80%

@media only screen and (max-width: 1441px)
    #construction-container
        width: 80%

@media only screen and (max-width: 1025px)
    #construction-container
        width: 90%

@media only screen and (max-width: 901px)
    #construction-container
        width: 90%

@media only screen and (max-width: 769px)
    #construction
        width: 100%
        height: auto
        padding: 6vh 0

    #construction-container
        width: 80%
        height: auto
        flex-direction: column
