#navigation
    width: 100%
    height: 6vh
    position: fixed
    display: flex
    justify-content: center
    z-index: 999
    transition: height 1s

#navigation-container
    width: 60%
    display: flex
    justify-content: space-around
    align-items: center

.navigation-text
    font-weight: bold
    font-size: 1rem
    padding: 4px 6px 6px
    border-radius: 15px
    color: rgb(255, 255, 255)

.navigation-text:hover
    cursor: pointer
    color: rgb(255, 255, 255)
    border-color: rgb(255, 255, 255)
    background-color: rgb(0, 133, 241)

#menu
    position: fixed
    display: none
    top: 10px
    left: 10px
    z-index: 1
    border-radius: 10px

#menu-button
    font-size: 20px
    padding: 5px 10px
    border: 1px solid rgba(255,255,255,.5)
    border-radius: 10px
    color: #fff

.menu-text
    font-weight: bold
    font-size: 1rem
    padding: 4px 6px 6px
    border-radius: 15px
    color: rgb(255, 255, 255)

.ant-drawer-title
    color: rgb(255, 255, 255) !important

@media only screen and (max-width: 769px)
    #menu
        display: block

    #navigation
        display: none
