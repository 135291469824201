#function-animator-animation-container
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

    canvas
        width: 80% !important
        height: 80% !important

#function-animator-description
    margin-bottom: 16px
    display: flex
    flex-direction: column
    align-items: center

    img
        width: 50%

    .mq-math-mode
        font-size: 110%

    .mq-root-block.mq-hasCursor
        text-wrap: balance

#function-animator-chart
    width: 100%

#function-animator-chart-title
    position: relative
    margin: auto
    width: max-content

#function-animator-canvas-div
    width: 100%
    height: 100vh

.function-animator-function-fields
    width: 97%
    background-color: rgb(255, 255, 255)
    color: rgb(0, 0, 0)
    padding: 5px
    border: 2px solid #1677FF
    border-radius: 5px

.function-animation-menu-form-spin
    .ant-row.ant-form-item-row
        flex-direction: row !important
        align-items: center !important
    .ant-col.ant-form-item-control
        width: 20% !important
        margin-left: 20px

@media only screen and (max-width: 1921px)
    #function-animator-description
        .mq-math-mode
            font-size: 100%

@media only screen and (max-width: 1441px)

@media only screen and (max-width: 1025px)
    #function-animator-description
        .mq-math-mode
            font-size: 90%

@media only screen and (max-width: 901px)

@media only screen and (max-width: 769px)

@media only screen and (max-width: 551px)

@media only screen and (max-width: 426px)

@media only screen and (max-width: 376px)
