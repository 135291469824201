#introduction
    width: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    background: url('https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/weblogin/web_bg1.jpg') center center / cover

#introduction-container
    width: 50%
    height: 60vh
    margin: auto
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    border: 5px solid black
    border-radius: 15px
    background: rgb(255, 255, 255, 0.8)

#introduction-text-container
    width: 50%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0 20px

    h1, h2, h3
        width: 60%
        margin-left: auto
        margin-right: auto

    h1, h2
        text-align: center

#introduction-image-container
    width: 50%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 0 20px

#introduction-avatar
    height: 80%
    border-radius: 15px

@media only screen and (max-width: 1921px)
    #introduction-container
        width: 80%

@media only screen and (max-width: 1441px)
    #introduction-container
        width: 80%

    #introduction-text-container
        h1, h2, h3
            width: 70%

@media only screen and (max-width: 1025px)
    #introduction-container
        width: 90%

@media only screen and (max-width: 901px)
    #introduction-avatar
        height: 70%

@media only screen and (max-width: 769px)
    #introduction
        width: 100%
        height: auto
        padding: 6vh 0

    #introduction-container
        width: 80%
        height: auto
        flex-direction: column

    #introduction-text-container
        width: 100%
        height: 100%
        padding: 0

        h1, h2, h3
            width: 70%

    #introduction-image-container
        width: 100%
        height: 50vh
        padding: 0

    #introduction-avatar
        height: 80%

@media only screen and (max-width: 426px)
    #introduction-text-container
        h3
            width: 70%

    #introduction-image-container
        height: 40vh

@media only screen and (max-width: 376px)
    #introduction-text-container
        h1
            width: 70%
        h3
            width: 80%

    #introduction-avatar
        height: 70%

// @media only screen and (max-width: 1921px)
// @media only screen and (max-width: 1441px)
// @media only screen and (max-width: 1025px)
// @media only screen and (max-width: 901px)
// @media only screen and (max-width: 769px)
// @media only screen and (max-width: 426px)
// @media only screen and (max-width: 376px)
