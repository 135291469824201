#footer
    width: 100%
    height: 10vh
    display: flex
    justify-content: center
    align-items: center
    background: rgb(0,0,0)
    color: #fff

#footer-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center

.footer-text:nth-of-type(even)
    cursor: pointer

@media only screen and (max-width: 1921px)
    #footer-container
        width: 80%

@media only screen and (max-width: 1441px)
    #footer-container
        width: 80%

@media only screen and (max-width: 1025px)
    #footer-container
        width: 90%

@media only screen and (max-width: 901px)
    #footer-container
        width: 90%

@media only screen and (max-width: 769px)
    #footer
        width: 100%
        height: auto
        padding: 3vh 0

    #footer-container
        width: 80%
        height: auto
        flex-direction: column
