.loschmidt-echo-paragraph-container
    width: 100%
    font-weight: normal
    text-indent: 20px

.loschmidt-echo-normal-container
    font-weight: normal

.loschmidt-echo-image-container
    width: 100%
    text-align: center

    .loschmidt-echo-image
        width: 100%
        max-width: 500px

    .table-image
        width: 100%
        max-width: 650px

    span
        font-weight: normal
        margin-bottom: 10px

    span.align-left
        text-align: left

.loschmidt-echo-go-to-reference
    color: -webkit-link
    cursor: pointer
    text-decoration: underline

.loschmidt-echo-go-to-reference:hover
    cursor: pointer

#loschmidt-echo-graphs-container
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-evenly

#double-pendulum-description
    margin-bottom: 16px
    display: flex
    flex-direction: column
    align-items: center

    img
        width: 50%

    .mq-math-mode
        font-size: 110%

    .mq-root-block.mq-hasCursor
        text-wrap: balance

#double-pendulum-buttons
    text-align: right

#animation-menu-submit-container
    display: flex
    flex-direction: row
    justify-content: space-evenly

#double-pendulum-canvas-div
    width: 100%
    height: 100vh

@media only screen and (max-width: 1921px)
    #double-pendulum-description
        .mq-math-mode
            font-size: 100%

@media only screen and (max-width: 1441px)

@media only screen and (max-width: 1025px)
    #double-pendulum-description
        .mq-math-mode
            font-size: 90%

@media only screen and (max-width: 901px)

@media only screen and (max-width: 769px)

@media only screen and (max-width: 551px)

@media only screen and (max-width: 426px)

@media only screen and (max-width: 376px)
