#experience
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-image: -webkit-linear-gradient(0deg, #766dff 0%, #88f3ff 100%)
    padding: 8vh 0 5vh

#experience-btn-container
    width: 50%
    display: flex
    justify-content: center
    margin-bottom: 100px

    .experience-btn
        padding: 0 40px
        color: white
        line-height: 50px
        font-size: 1rem
        border: 2px solid white
        border-radius: 5px
        margin: 0 20px
        background-color: rgba(255, 255, 255, 0.2)
        transition: color 0.7s, background-color 0.7s

    .experience-btn:hover
        cursor: pointer

#experience-container
    width: 50%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.experience-list
    width: 100%
    min-height: 300px
    margin: auto
    position: relative
    padding: 40px 0
    list-style: none

    li
        margin-bottom: 50px
        position: relative

        span
            height: 15px
            width: 15px
            border-radius: 50%
            display: block
            background: rgba(255, 255, 255, 0.2)
            position: absolute
            left: 50%
            top: 35%
            transform: translateX(-50%)

        span:before
            content: ''
            height: 7px
            width: 7px
            background: #fff
            border-radius: 50%
            position: absolute
            left: 52%
            top: 4px
            transform: translateX(-50%)

.experience-list:before
    content: ""
    height: 90%
    width: 5px
    background: rgba(255, 255, 255, 0.2)
    position: absolute
    left: 50%
    top: 0px
    transform: translateX(-50%)

.experience-item-container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    width: 100%

.experience-item
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    width: 100%

    .experience-item-date
        width: 50%
        text-align: right
        color: rgb(255, 255, 255, 0)
        transition: color 0.4s

        p
            padding-right: 20%

    .experience-item-body
        text-align: left
        width: 40%
        padding-top: 4px

        span
            height: auto
            width: auto
            display: block
            position: relative
            background: none
            border-radius: 0
            color: rgb(255, 255, 255, 0)
            top: 0px
            font-weight: bold
            font-size: 1rem
            transition: color 0.4s

        span:before
            content: none
            height: 0
            width: 0

        ul
            padding-inline-start: 20px
        li
            margin-bottom: 0

@media only screen and (max-width: 1921px)
    #experience-container
        width: 80%

@media only screen and (max-width: 1441px)
    #experience-container
        width: 80%

@media only screen and (max-width: 1025px)
    #experience-container
        width: 90%

@media only screen and (max-width: 901px)
    #experience-container
        width: 90%

@media only screen and (max-width: 769px)
    #experience
        width: 100%
        height: auto
        padding: 6vh 0 0

    #experience-container
        width: 80%
        height: auto
        flex-direction: column

    #experience-btn-container
        width: 100%

@media only screen and (max-width: 551px)
    #experience-btn-container
        margin-bottom: 80px

        .experience-btn
            padding: 0 30px

    .experience-list

        li
            span
                left: 15%
                top: 20%

    .experience-list:before
        left: 15%

    .experience-item
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        width: 100%

        .experience-item-date
            width: 75%
            text-align: left
            padding-left: 16px
            margin-left: 20%
            left: 20px

        .experience-item-body
            width: 80%
            margin-left: 20%
            padding-top: 0px

            span
                left: 50%

@media only screen and (max-width: 426px)
    #experience-btn-container
        .experience-btn
            padding: 0 20px

@media only screen and (max-width: 376px)
    #experience-btn-container
        .experience-btn
            padding: 0 15px
